<template>
    <div v-if="props.showModal" class="modal-overlay">
      <div class="modal-content">
        <!-- <p class="modal-title">Up to 10 chat rooms per group</p> -->
        <p class="modal-subtitle">컴포넌트를 저장 할 수 없습니다.</p>
        <p v-if="props.messages.length > 0" class="modal-subtitle">{{ messages[0] }}</p>
        <div class="modal-footer-new">
          <button class="modal-button p-button-secondary" @click="check">확인</button>
        </div>
        
        
      </div>
    </div>
  </template>
  
  <script setup>
  import { defineEmits, defineProps } from 'vue';
  const props = defineProps({
    showModal: {
      type: Boolean,
      required: true,
    },
    messages: {
      type: Array,
      required: true,
    },
  });
  const emit = defineEmits(['accept']);
  
  const check = () => {
    emit('accept');
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 300px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .modal-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .modal-subtitle {
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .modal-button {
    background-color: #3c6efb;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal-button:hover {
    background-color: #2b50e3;
  }
  .modal-footer-new{
    display: flex;
    gap: 16px;
    justify-content: center;
  }
  </style>